import { Pipe, PipeTransform } from '@angular/core';

import { getShopAlias } from './get-shop-alias';

@Pipe({ name: 'shopAlias' })
export class ShopAliasPipe implements PipeTransform {
  transform(shopName: string): string {
    return getShopAlias(shopName);
  }
}
